


































import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { DateHelper } from '@/utils/helpers/date-helper';

@Component({})
export default class DatePickerField extends Vue {
  dateFieldModel = '';

  datePickerModel = '';

  @VModel({ required: true })
  model!: Date | undefined;

  @Prop()
  readonly rules!: (CallableFunction | unknown[])[];

  @Prop()
  label!: string;

  @Prop({ default: false })
  readonly solo!: boolean;

  @Prop({ default: false })
  dense!: boolean;

  @Prop({ default: false })
  hideDetails!: boolean | string;

  @Prop({ default: null })
  min!: string;

  @Prop({ default: null })
  max!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Watch('model', {
    deep: true,
    immediate: true,
  })
  onModelChange(value: Date): void {
    this.datePickerModel = value ? DateHelper.formatToIsoDateString(value) : '';
    this.$nextTick(() => {
      this.dateFieldModel = value ? this.$d(value, 'short') : '';
    });
  }

  onDateFieldChange(value: string): void {
    const dateObj = dayjs(value, DateHelper.getLocaleDateFormat(this.$i18n.locale), true);
    this.model = dateObj.isValid() ? dateObj.toDate() : undefined;

    this.$emit('change', this.model);
  }

  onDateFieldBlur(): void {
    if (!this.model) {
      this.dateFieldModel = '';
    }
  }

  onDatePickerChange(value: string): void {
    this.model = dayjs(value).toDate();

    this.$emit('change', this.model);
  }

  get dateFieldMask(): string {
    return DateHelper.getLocaleDateFormat(this.$i18n.locale).replace(/[A-Z]/g, '#');
  }
}
